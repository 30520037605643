body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;
  }
.padding20{
  padding: 12px 40px !important;
}
  .error-message-copied {
    display: flex;
    align-items: center;
    color: #2D2D2D;
    font-weight: 700;
    font-size: 14px;
    padding: 10px 16px;
    border-radius: 10px;
    width: fit-content;
    margin: 16px auto;
    position: absolute;
    top: -92px;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
}

  .popupheader{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .mt-30{
    margin-top: 30px;
  }
  .mt-2{
    margin-top: 2px;
  }
  .ml-5{
    margin-left: 5px;
  }
  .copiedtext{
    font-weight: 600;
    background: #152745;
    color: white;
    padding: 10px 31px 10px 31px;
    margin-top: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 16px;
    /* margin-left: 30%; */
    /* margin-right: 30%; */
  }
  .mr-8{
    margin-right: 8px;
  }
  .ml-35{
    margin-left: 36px;
  }
  .font-600{
    font-weight: 600;
  }
  .pl-40{
    padding-left: 28px !important;
  }
  .mr-20{
    margin-right: 20px;
  }
  .mt-5{
    margin-top: 5px;
  }

  .ml-4{
    margin-left: 4px !important;
  }
  .bankname {
    background-image: linear-gradient(to left, #152745, #3461AB);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 14px;
    font-weight: 600;
    opacity: 60%;
}


.banknamemajor{
  font-size: 14px;
    font-weight: 600;
}
  .nohistory-found{
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    padding: 30px 0px 60px
  }
  .container {
    /* display: flex; */
    padding: 0 !important;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    /* background-color: #fff; */
    /* padding: 0 20px; */
    width: 100%; 
     max-width: 530px !important;
     margin: 0 auto;
  }
  
  

  
#backgroung{
    /* background: #3464b4; */
    background: linear-gradient(to left, #3464b4, #3464b4); 
    border-radius: 0px;
}
  button {
    cursor: pointer;
  }
  
  a {
    font-size: 14px;
    color: orange;
    text-decoration: none;
  }
  
  h1, h2, h3, p {
    margin: 0;
  }
  
  * {
    box-sizing: border-box;
  }
  .f-weight700{
    font-weight: 700;
  }
  .marginleft-right-5{
    margin-left: 5px;
    margin-right: 5px;
  }
  .commonOutline{
    outline: 1px solid rgba(21, 39, 69, 0.2);
    border-radius: 10px;
    padding: 7px;
    position: relative;
    max-width: 30%;
    width: 100%;
    min-height: 60px;
  }
  .popup-content-wrapper{
    padding: 20px;
  }

  
  /* .commonOutline {
    outline: 1px solid rgba(21, 39, 69, 0.2);
    border-radius: 10px;
    padding: 7px; 
    width: 109px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
} */
.termsandcondition{
  margin-top: 16px;
  margin-bottom: 20px;
  font-size: 14px;
  text-decoration: underline;
  font-weight: 700;
}
.referNowSection{
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}
.floatright{
  float: right;
}
  .paymecolour{
    color: rgba(252, 102, 3, 1) !important;
  }
  
.paymecolour.termsandcondition a {
  color: rgba(252, 102, 3, 1); 
  /* text-decoration: none;  */
}

.paymecolour.termsandcondition a:hover {
  text-decoration: underline; 
}

  .paymegreencolour{
    color: rgba(22, 201, 111, 1) !important;
  }
  .width100{
    width: 100%;
    background: linear-gradient(to left, rgba(255, 231, 217, 0.5), rgba(255, 255, 255, 1)); 
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
    padding: 10px 20px;
    text-align: center;
    border-radius: 0px;
  }
  .mr-10{
    margin-right: 10px;
  }
  .paymecolournew{
    color:rgb(255, 162, 0);
  }
  .inprog{
    font-size: 14px !important;
    font-weight: 600 !important;
  }
  .inprogress{
    color: rgb(255, 162, 0);
    font-size: 18px;
    font-weight: 600;
  }
  .how-it-works-sec{
    width: 100%;
    background: linear-gradient(to bottom, rgba(255, 231, 217, 0.5), rgba(255, 255, 255, 1));
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
    padding: 10px 20px;
    align-items: center;
    text-align: center;
    border-radius: 0px;
      }
      .flex{
        display: flex;
      }

      .moreloanmorecoin{
        font-size: 16px !important;
        font-weight: 700 !important;
        color: rgba(21, 39, 69, 1) !important;
        margin-bottom: 5px;
      }

  .flex-row-center{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .flex-col{
    display: flex;
    flex-direction: column;
  }
  .align-centre{
    align-items: center;
  }
  .mb-25{
    margin-bottom: 25px;
  }
  .flex-row{
    display: flex;
    flex-direction: row;
  }
  .flex-col span{
    margin-top: 4px;
    margin-bottom: 8px;
  }
  
  

 /* top section css dashboard */

 .mainbackground{
  background: white;
    padding-top: 30px !important;
    /* padding: 30px 20px; */
    /* border-radius: 30px; */
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    margin-bottom: 126px;
 }
  .top-section {
    background-image: url('../assets//dashboardmain.png');
    background-size: cover;
    background-position: center;
    padding: 60px 20px;
    text-align: center;
    position: relative;
    height: 428px;
    border-radius: 0px 0px 30px 30px;
  }
  .historym-margin-fix{
    padding: 0px 24px 0px 40px !important;
  }
  .mb-12{
    margin-bottom: 12px;
  }
  .history-trans-heading{
    font-size: 18px  !important;
    font-weight: 600 !important;
    color: rgba(21, 39, 69, 1)  !important;
  }

  .top-section-dashboard{
    background-image: url('../assets//dashboardmain1.png');
    background-size: cover;
    width: 100%;
    background-repeat: no-repeat;
    background-position: top;
    padding: 60px 20px;
    text-align: center;
    position: relative;
    height: 385px;
    border-radius: 0px 0px 30px 30px;
  }

  .top-sections {
    background-image: url('../assets//backgroundimg.png');
    background-size: cover;
    background-position: center;
    padding: 60px 20px;
    text-align: center;
    position: relative;
    height: 428px;
    border-radius: 0px 0px 30px 30px;
    max-width: 570px;
    width: 100%;
    margin: 0 auto;
  }
  .paddingnow40{
    padding: 0px 40px !important;
  }
  
  .back-btn {
    position: absolute;
    left: 20px;
    top: 25px;
    background: none;
    border: none;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
  }
  
  h1 {
    color: white !important;
    font-size: 24px;
    font-weight: bold;
    /* margin-bottom: 20px; */
  }
  
  .balance-info {
    background-image: url('../assets/globe.png') !important;
    background-position: top left; 
    background-repeat: no-repeat  !important; 
    background-size: 148px auto !important; 
    transform: translateY(195px);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white; 
    padding: 21px 0px 0px;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.how-it-works-info {
  background-position: top left;
    background-repeat: no-repeat !important;
    background-size: 148px auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 150px;
    margin-top: 215px;
}
.underline{
  text-decoration: underline;
}
.knowmorefont{
  font-size: 14px;
  font-weight: 700;
}
.flex-col-cen{
  display: flex;
    flex-direction: column;
    align-items: center;
}
    .balance{  
    width: 100%;
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
    }
  
  .coins p {
    font-size: 32px;
    font-weight: 800;
    color: rgba(21, 39, 69, 1);
    margin: 0;
    text-align: left;
  }
  
  .coins span {
    display: block;
    font-size: 14px;
    font-weight: 700;
    color: #666;
  }
  
  .claim-btn {
    background-color: #ff6700;
    color: white;
    width: 139px;
    height: 54px;
font-weight: 700;
    padding: 10px 20px;
    font-size: 18px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .refer-btn-popup {
    background-color: #ff6700;
    color: white;
    width: 211px;
    height: 58px;
    font-weight: 700;
    padding: 10px 20px;
    font-size: 18px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
  }
  .refer-btn{
    background-color: #ff6700;
    color: white;
    width: 340px;
    height: 60px;
font-weight: 700;
    padding: 10px 20px;
    font-size: 18px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .claimed-info {
    color: #ff6700;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 700;
  }

  

  /* Mid section the start section of dashboard */
  .stats-section {
    display: flex;
    justify-content: space-around;
    padding: 25px 5px 25px 5px;
    background: white;
    border-radius: 12px;
    margin: 95px 0 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  .rupeecoin{
    font-size: 14px;
    font-weight: 700;
    color: rgba(21, 39, 69, 0.8);
  }
  .stat {
    text-align: center;
  }
  
  .stat img {
    width: 50px;
    height: 50px;
    margin-bottom: 5px;
    margin-top: 5px;
    margin-right: 6px;
  }
  
  .stat p {
    font-size: 24px;
    color: #333;
    margin: 0;
  }
  
  .stat span {
    font-size: 12px;
    font-weight: 600;
    color: #666;
    position: absolute;
    width: max-content;
    top: -7px;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
  }

  /* .stat span {
    font-size: 12px;
    font-weight: 600;
    color: #666;
    position: absolute;
    top: 0px;
    background: #fff;
} */

/* how it work section of dashboard  */
  .how-it-works {
    background-color: white;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 5px;
    margin: 9px 0px 0px;
    border-radius: 5px;
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
    transform: translateY(10px);
    /* padding: 10px 20px !important; */
  }
  .flex-row-center-spacebtn{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .flex-row-center-trans{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 22px;
    box-shadow: 0 4px 2px -2px rgba(21, 39, 69, 0.1);
  }
  .font70030{
    font-size: 30px;
    font-weight: 700;
  }

  .search-container {
    position: relative;
    display: inline-block;
}
.mb-1{
  margin-bottom: 1px;
}
.mb-9{
  margin-bottom: 9px;
}
.font700{
  font-weight: 700;
}
.search-history {
  border-radius: 10px;
  width: 333px;
  height: 45px;
  border: 0.5px solid rgba(21, 39, 69, 1);
  margin-top: 20px;
  margin-left: 29px;
  padding-left: 48px;
  padding-top: 6px;
  font-size: 14px;
  font-weight: 500;
  opacity: 0.4;
}
.notransctionfound{
  display: flex;
    justify-content: center;
    margin-top: 50px;
}

.search-icon {
  position: absolute;
  top: 65%;
  left: 40px;
  transform: translateY(-41%);
  font-size: 18px;
  color: rgba(21, 39, 69, 0.4);
  pointer-events: none;
}

.search-history:focus,
.search-history:hover {
    outline: none;
    border-color: rgba(21, 39, 69, 0.4); 
}


  .all-transction-header{
    display: flex !important;
    flex-direction: column;
    align-items: start !important;
  }
  .how-it-works button {
    /* width: 100%; */
    background-color: transparent;
    font-size: 18px;
    font-weight: 700;
    color: #333;
    border: none;
    cursor: pointer;
    padding: 10px 10px 10px 6px;
  }
  
  .how-it-works-content {
    padding: 10px;
    color: #666;
    padding-top: 30px;
  }
  
  .how-it-works-content p {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    line-height: 20.93px;
    color: rgba(21, 39, 69, 0.6);
  }
  

  /* history section of dashboard  */
  .history-section {
    padding: 0px 16px 0px 16px;
    
    background: white;
}
.history-sections {
    background: white;
    max-width: 530px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 150px;
}
  
  .history-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 27px;
    margin: 27px 16px 17px 14px;
  }
  .history-header-trans {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .history-header h2 {
    margin: 0;
    font-size: 1.5em;
  }

  .view-all {
    text-decoration: underline !important;
    color: #f57200;
    font-weight: bold;
  }

  .history-day {
    margin-bottom: 20px;
  }


.history-day h3 {
  margin-bottom: 10px;
  font-size: 1.2em;
  
  color: #555;
  padding: 3px 0px 3px;
  background: rgba(232, 233, 236, 0.3);
}
img.hystoryminiicon {
  vertical-align: sub;
}

.transaction-details .verticalline{
  margin-left: 7px;
  margin-right: 9px;
}
.mr-50{
  margin-right: 50px;
}
.transaction-details .date-icon:first-child{
  padding-left: 0px !important;
  padding-right: 4px ;
}
.transaction-details .time-icon , .transaction-details.earliat-transtions .date-icon , .transaction-details.earliat-transtions .date-icon{
  padding-left: 10px;
  padding-right: 4px;
}
span.date-icon {
  margin-right: 4px;
}
.history-day-main h3 {
  width: 100%;
  margin-bottom: 10px;
  font-size: 1.2em;
  color: #555;
  padding: 3px 0px 3px 30px;
  background: rgba(232, 233, 236, 0.3);
}

.history-item-today {
  border-bottom: 1px solid #ddd;
  padding: 0px 40px;

}

.history-item {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}



.history-item-content {
  display: flex;
  flex-direction: column;
}

.history-item-main{
  display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}
.redeemed-status {
  color: #f57200;
}

.positive-amount {
  color: rgba(22, 201, 111, 1) !important;
  
  font-weight: 600;
  font-size: 18px !important;
}

.negative-amount {
  color: rgba(255, 0, 0, 1) !important;
  font-weight: 600;
  font-size: 18px !important;
}

.transaction-details {
  color: #777;
  font-size: 0.9em;
}

  .history-section h2 {
    font-size: 18px;
    font-weight: 700;
    color: #333;
    /* margin-bottom: 10px; */
  }
  .history-sections h2 {
    font-size: 20px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .history-section a {
    font-size: 14px;
    color: #ff6700;
    text-decoration: none;
    float: right;
  }
  .history-sections a {
    font-size: 14px;
    color: #ff6700;
    text-decoration: none;
    float: right;
  }
  
  .history-item {
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #eaeaea;
    padding: 10px 30px !important; 
  }
  
  .history-item p {
    margin: 0;
    font-size: 14px;
    color: #333;
    display: flex;
    align-items: center;
  }
  .history-item p:first-of-type {
    font-size: 12px;
    font-weight: 500;
    color: rgba(21, 39, 69, 0.6);
    display: flex;
    align-items: center;
  }
  .historyHeading{
    font-size: 18px !important;
    font-weight: 600 !important;
    color: rgba(21, 39, 69, 1) !important;
    margin-bottom: 10px !important;
  }
 
 
  /* .hystoryminiicon{
    margin-right: 5px;
    margin-left: 2px;

  } */
  .mb-100{
    margin-bottom: 100px;
  }
  .paymecoinfixhistory{
    transform: translateY(6px);
  }
  .paymecoinfixhistory1{
    transform: translateY(-2px);
  }
  .todayyest{
    font-size: 16px !important;
    font-weight: 600;
    color: rgba(21, 39, 69, 0.4) !important;
  }
  
  .history-item .positive {
    color: #4caf50;
  }
  
  .history-item .negative {
    color: #f44336;
  }
  
  .positive-amount {
    color: green;
  }
  
  .negative-amount {
    color: red;
  }

  /* how it work section  */
  .referral-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background: #fff;
    width: 100%;
    max-width: 530px;
  }
  
  .section {
    display: flex;
    align-items: center;
    /* background-color: #f8f9fa; */
    /* padding: 15px; */
    /* border-radius: 8px; */
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
  }
  
  .icon {
    margin-right: 20px;
  }
  
  .icon img {
    width: 141.67px; 
    height: auto;
  }
  
  .text-content {
    font-size: 14px;
    color: #333;
    text-align: left;
    font-weight: 600;
    line-height: 18.31px;
  }
  
  .highlight {
    color: #ff6600; 
    font-weight: 700;
  }
  
  @media (max-width: 768px) {
    .section {
      flex-direction: row;
      text-align: center;
    }
  
    .icon {
      /* margin-bottom: 10px; */
    }
  }
  
  .gradient-bg {
    background: rgba(21, 39, 69, 0.1);
    height: 1px;
    width: 100%;
  }

  .gradient-bg2 {
    background: rgba(21, 39, 69, 0.05);
    height: 2px;
    width: 100%;
  }
  
  .refer-now-section {
    position: fixed;
    bottom: 0;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    background-color: #fff;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    /* padding: 20px; */
    text-align: center;
    z-index: 2;
    max-width: 530px;
    margin: 0 auto;
  }

  .refer-now-section-all {
    position: fixed;
    bottom: 0;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    background-color: #fff;
    /* padding: 20px; */
    text-align: center;
    z-index: 2;
    max-width: 530px;
    margin: 0 auto;
  }
  



  /* error css */
  .error-message {
    display: flex;
    align-items: center;
    background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 231, 217, 0.4)); 
    /* border: 1px solid #FFCD96; */
    color: #2D2D2D;
    font-weight: 700;
    font-size: 14px;
    padding: 10px 16px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    width: fit-content;
    margin: 16px auto;
    outline: 2px solid rgba(252, 102, 3, 1);
    position: absolute;
    top: -75px;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
    background: #fff;
  }
  
  .error-message .icon {
    background: linear-gradient(to bottom, rgba(219, 169, 72, 1), rgba(255, 196, 107, 1), rgba(255, 163, 24, 1));
    color: #fff;
    border-radius: 6px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
    margin-right: 10px;
    font-size: 12px;
  }

  /* popup style */



  /* Popup container */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 1000;
  }

.popup {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    height: 72%;
    max-height: 750px;
    width: 94%;
    max-width: 495px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    overflow-y: auto;
    font-family: 'Arial', sans-serif;
}
.positionfixedbottom0 {
  /* position: fixed; */
  bottom: 0;
  left: 0;
  right: 0; 
  background-color: white; 
  padding: 10px 0; 
}
  body.no-scroll {
    overflow: hidden;
  }
  

/* Popup header */
.popup-header {
  background: linear-gradient(to bottom, rgba(21, 39, 69, 1), rgba(52, 97, 171, 1)); 
  color: white !important; 
  height: 131px !important; 
  border-bottom-left-radius: 30px !important; 
  border-bottom-right-radius: 30px !important; 
  position: sticky; 
  top: 0;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ppoheader{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 25px;
}
.headerheadingpopup{
  font-size: 24px;
    font-weight: 700;
}

.popupouterbox{
  height: 450px;
  margin-top: -50px;
  position: relative;
  overflow: scroll;
  z-index: 1;
}
.button-wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  background: #fff;
  padding: 10px 20px 40px 20px;
  margin-bottom: 0px !important;
  .btn {
    max-width: 340px;
    width: 100%;
    font-size: 20px;
    background-color: #ff6700;
  }
}

.popupBox{
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 12px 0px 12px 0px;
  border-radius: 4px;
  margin-bottom: 20px;
  background: #fff;
}

.popup-header h2 {
  margin: 0;
}

/* Close button */
.close-btn {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

/* Popup content */
.popup-content {
  padding: 20px;
  /* background: #fff; */

}
.mb-100{
  margin-bottom: 100px;
}
.textaligncenter {
  text-align: center; 
  display: flex; 
  justify-content: center; 
  width: 100%;
  z-index: 2;
}
.faq-item h3 {
  margin-top: 0;
  font-size: 16px;
  color: #00325b;
  font-weight: 600;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 8px;
}

.faq-item p {
  margin: 5px 0;
  font-size: 14px;
  padding-left: 12px;
  padding-right: 12px;
  font-weight: 500;
  /* color: #333; */
  padding-top: 12px;
  line-height: 18.31px;
  color: rgba(21, 39, 69, 0.8);
}



/* Popup footer */
.popup-footer {
  padding: 15px;
  background-color: white;
  text-align: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}


/* Styles for blur effect */
.blur-background {
  filter: blur(5px); 
  pointer-events: none; 
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.faq-popup {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1001; 
}


/* bottom popup css */
.withdraw-popup-container {
  position: relative;
}

.withdraw-open-button {
  padding: 10px 20px;
  background-color: #f57200;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}

.withdraw-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1000;
  /* padding: 20px;  */
  box-sizing: border-box;
}

.withdraw-popup-content {
  background-color: white;
  padding: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100%;
  max-width: 400px;
  position: relative;
}

.withdraw-popup-close-button {
  background-color: white;
  padding: 20px;
  border-radius: 8px 8px 0 0;
  width: 100%;
  max-width: 400px;
  position: relative;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.withdraw-popup-header {
  text-align: center;
  margin-bottom: 20px;
}

.withdraw-popup-image {
  max-width: 100%;
  height: auto;
}

.withdraw-popup-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.withdraw-popup-amount {
  color: #f57200;
}

.withdraw-popup-text {
  font-size: 16px;
  margin-bottom: 20px;
}

.withdraw-popup-account {
  margin-bottom: 20px;
}

.withdraw-popup-select {
  display: flex;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  max-width: 324px;
  border-radius: 5px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.errorpopup{
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  display: flex;
  align-items: center;
}
.mrml10{
  margin-left: 10px;
    margin-right: 10px;
}
.ml-25{
  margin-left: 25px;
}

.withdraw-popup-submit-button {
  width: 100%;
  padding: 10px;
  background-color: #f57200;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}
.pd-3{
  padding: 3px;
}
.loan-animation-container {
  background-color: rgba(255, 255, 255, 0.06); 
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.loan-animation-line,
.loan-animation-line-small { 
  position: absolute;
  background-color: white;
  transform: rotate(10deg);
  animation: loan-animation-slide 2s linear infinite;
}

.loan-animation-line { 
  width: 3px; 
  height: 100px;
  top: -50px; 
  left: 0;
}

.loan-animation-line-small { 
  width: 1px; 
  height: 70px; 
  top: -45px; 
  left: 15px; 
  animation: loan-animation-slide-small 2s linear infinite; 
}

@keyframes loan-animation-slide {
  0% {
    transform: rotate(10deg) translateX(0);
  }
  50% {
    transform: rotate(10deg) translateX(190px);
  }
  100% {
    transform: rotate(10deg) translateX(0);
  }
}

@keyframes loan-animation-slide-small {
  0% {
    transform: rotate(10deg) translateX(0);
  }
  50% {
    transform: rotate(10deg) translateX(190px);
  }
  100% {
    transform: rotate(10deg) translateX(0);
  }
}





.loader-text {
  padding-top: 50px;
  top: 50%;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  color: #ff5700;
}

.loader_wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  z-index: 999999;
  transform: translate(-50%, -50%);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.5);
}

.lds-roller {
  display: inline-block;
  width: 64px;
  position: absolute;
  height: 64px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #ff5700;
  margin: -3px 0 0 -3px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}




/* Styles for the popup modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* On top of all other elements */
}
.withdraw-popup{
  font-size: 18px;
    font-weight: 700;
}

.modal-content {
  background: #fff !important;
  width: 350px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: Arial, sans-serif;
  width: 100%;
  max-width: 336px;
  border-radius: 10px !important;
}

.modal-header {
  margin-bottom: 15px;
}

.modal-checkmark {
  font-size: 40px;
  color: green;
}

.modal-body {
  font-size: 16px;
  color: #333;
}

.modal-body h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.modal-footer {
  margin-top: 20px;
}
.transcrtionid{
  font-size: 12px;
    font-weight: 600;
    color: rgba(21, 39, 69, 0.6);
}

.modal-btn {
  background-color: white;
  color: white;
  width: 150px;
  height: 50px;
  margin: 0 auto;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 18px;
  outline: 2px solid rgba(252, 102, 3, 1) !important;
  font-weight: 700;
  color: rgba(252, 102, 3, 1);
}


